<template>
  <div ref="root">
    <slot name="placeholder" :show-placeholder="showPlaceholder" />
    <slot :show="showComponent" />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref, watch } from 'vue'

import { useIntersectionObserver } from '@vueuse/core'

const showPlaceholder = ref(true)
const showComponent = ref(false)

const emit = defineEmits(['show'])
const root = ref<HTMLDivElement | null>(null)
const hasTriggeredIntersection = ref(false)

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    showPlaceholder.value = false
    showComponent.value = true

    hasTriggeredIntersection.value = false

    emit('show')
  }
})

watch(hasTriggeredIntersection, () => {
  if (hasTriggeredIntersection.value) {
    stop()
  }
})

onBeforeUnmount(() => {
  stop()
})
</script>
