<template>
  <video
    ref="videoObserverTarget"
    aria-hidden="true"
    :class="[
      'm-auto block w-full',
      { 'rounded-lg h-full': !fullscreen, 'md:aspect-square': fullscreen },
    ]"
    v-bind="attributes"
    muted
    :poster="thumbnail?.src"
  >
    <source :src="video.url" :type="video.contentType" />
  </video>
</template>

<script setup lang="ts">
import {
  type MediaHTMLAttributes,
  computed,
  onBeforeUnmount,
  ref,
  watch,
} from 'vue'

import type {
  Image,
  VideoAssetFile,
} from '@backmarket/http-api/src/api-specs-content'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useIntersectionObserver } from '@vueuse/core'

import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

const props = withDefaults(
  defineProps<
    Pick<ContentBlockProps, 'tracking'> & {
      fullscreen?: boolean
      thumbnail?: Image
      trackImpression?: boolean
      video: VideoAssetFile
    }
  >(),
  {
    fullscreen: false,
    trackImpression: true,
    thumbnail: undefined,
  },
)

const { trackVideoImpression } = useTracking()

const attributes = computed(() =>
  props.fullscreen
    ? { autoplay: 'autoplay', controls: 'controls' }
    : { loop: 'loop' },
) as Partial<MediaHTMLAttributes>

const videoObserverTarget = ref<HTMLElement | null>()
const hasTriggeredImpression = ref(false)

const { stop } = useIntersectionObserver(
  videoObserverTarget,
  ([{ isIntersecting }]) => {
    if (
      isIntersecting &&
      props.trackImpression &&
      typeof props.tracking !== 'undefined'
    ) {
      trackVideoImpression(props.tracking)
      hasTriggeredImpression.value = true
    }
  },
)

watch(hasTriggeredImpression, () => {
  if (hasTriggeredImpression.value) {
    stop()
  }
})

onBeforeUnmount(() => {
  stop()
})
</script>
