<template>
  <BlockTitle v-if="renderChildren" :subtitle="subtitle" :title="title" />
  <LoadInViewport
    v-if="renderChildren"
    class="m-auto block size-full"
    @show="trackBlockImpression"
  >
    <template #default="{ show }">
      <RevCardCarousel
        v-if="show"
        :next-alternative-text="i18n(translations.nextSlideText)"
        :prev-alternative-text="i18n(translations.prevSlideText)"
      >
        <RevModal
          v-for="(videoContent, index) in filteredVideos"
          :key="videoContent.id"
          :name="videoContent.video.title"
          :title="videoContent.video.title"
          variant="confirmation"
          @close="pauseVideo(index)"
        >
          <template #trigger>
            <RevButtonBase
              :aria-label="videoContent.video.title"
              class="rounded-lg cursor-pointer"
              @click="handleVideoClick(videoContent, index)"
              @focusin="playVideo(videoContent, index)"
              @focusout="pauseVideo(index)"
              @mouseenter="playVideo(videoContent, index)"
              @mouseleave="pauseVideo(index)"
            >
              <div v-if="thumbnailsShownStatus[videoContent.id]">
                <RevIllustration
                  v-if="videoContent.image?.src"
                  v-bind="videoContent.image"
                  class="rounded-lg"
                />
                <RevSkeleton
                  v-else
                  :data-test="`${videoContent.id}-skeleton`"
                  :height="placeholderHeight"
                  shape="rectangle"
                />
              </div>
              <VideoRenderer
                v-show="!thumbnailsShownStatus[videoContent.id]"
                ref="videoRefs"
                :data-test="videoContent.id"
                :thumbnail="videoContent.image"
                :tracking="tracking"
                :video="videoContent.video.file"
              />
            </RevButtonBase>
          </template>
          <template #body>
            <VideoRenderer
              ref="videoRefs"
              class="mb-24"
              fullscreen
              :thumbnail="videoContent.image"
              :track-impression="false"
              :tracking="tracking"
              :video="videoContent.video.file"
            />
          </template>
        </RevModal>
      </RevCardCarousel>
    </template>

    <template #placeholder="{ showPlaceholder }">
      <RevSkeleton
        v-if="showPlaceholder"
        :height="placeholderHeight"
        shape="rectangle"
      />
    </template>
  </LoadInViewport>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, ref } from 'vue'

import type {
  VideoContent,
  VideosContent,
} from '@backmarket/http-api/src/api-specs-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevIllustration } from '@ds/components/Illustration'
import { RevModal } from '@ds/components/Modal'
import { openModal } from '@ds/components/ModalBase'
import { RevSkeleton } from '@ds/components/Skeleton'

import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import BlockTitle from '../../shared-components/BlockTitle/BlockTitle.vue'

import LoadInViewport from './LoadInViewport.vue'
import VideoRenderer from './VideoRenderer.vue'
import translations from './VideosBlock.translations'

export type VideosBlockProps = Required<ContentBlockProps> & VideosContent

const props = withDefaults(defineProps<VideosBlockProps>(), {
  subtitle: '',
  title: '',
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { trackVideosBlockImpression, trackClick, trackHoverOver } = useTracking()
const i18n = useI18n()

const isPlaying = ref(false)
const focusTimeout = ref<ReturnType<typeof window.setTimeout>>()

type VideoContentRequired = Omit<VideoContent, 'video'> & {
  video: NonNullable<VideoContent['video']>
}

// If the videos are in draft, they could be undefined
const filteredVideos = computed<VideoContentRequired[]>(() =>
  props.videos.filter(
    (videoContentFiltered): videoContentFiltered is VideoContentRequired =>
      !isEmpty(videoContentFiltered.video),
  ),
)

const thumbnailsShownStatus = ref<Record<string, boolean>>(
  filteredVideos.value.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}),
)
const placeholderHeight = computed(() => {
  const height = filteredVideos.value[0].image?.height || 455

  return `${height}px`
})

const renderChildren = computed(() => filteredVideos.value.length > 0)

const trackBlockImpression = () => {
  trackVideosBlockImpression({
    ...props.tracking,
    blockPosition: props.blockPosition,
  })
}

const videoRefs = ref<InstanceType<typeof VideoRenderer>[]>([])

const getVideoFromIndex = (index: number) =>
  videoRefs.value[index].$el as HTMLVideoElement

const playVideo = ({ id, video }: VideoContentRequired, index: number) => {
  if (!isPlaying.value) {
    thumbnailsShownStatus.value[id] = false
    void getVideoFromIndex(index).play()
    isPlaying.value = true
    focusTimeout.value = setTimeout(() => {
      trackHoverOver({
        ...props.tracking,
        position: index,
        name: video.title,
      })
    }, 2000)
  }
}

const pauseVideo = (index: number) => {
  if (isPlaying.value && typeof focusTimeout.value !== 'undefined') {
    getVideoFromIndex(index).pause()
    isPlaying.value = false
    clearTimeout(focusTimeout.value)
  }
}

const handleVideoClick = ({ video }: VideoContentRequired, index: number) => {
  openModal(video.title)
  trackClick({
    ...props.tracking,
    position: index,
    name: video.title,
  })
}

onBeforeUnmount(() => {
  if (focusTimeout.value !== undefined) {
    clearTimeout(focusTimeout.value)
  }
})
</script>
